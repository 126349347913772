.spell {
  border-bottom: 1px solid green !important;
  padding-bottom: 70px;
}
.body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 0 auto;
}

.w100{
  width: 100% !important;
}

.flex-center{
  display : flex;
  flex-direction: row;
  align-items: center;
}

.announcement-bar {
  background-color: #ffeb3b; /* Yellow background */
  color: #000; /* Black text */
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays on top */
}

.announcement-bar .close-button {
  background: none;
  border: none;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1; /* This pushes the footer to the bottom */
}

footer {
  padding: 20px;
  background-color: #004d40; /* Adjust as needed */
  color: #fff;
}